import { IInitialValues } from './type';

export const INITIAL_VALUES: IInitialValues = {
  id: undefined,
  upload_files: [],
  email_permission: false,
  policy_number: '',
  start_date: '',
  end_date: '',
  description: '',
  first_name: '',
  last_name: '',
  turkish_id: '',
  birthday: '',
  organization: 0,
  recent_status: '',
};
