import { FC, useRef } from 'react';
import { Flex, Input, Select } from 'antd';
import {
  ErrorMessage,
  LabelTitle,
  MailCheckbox,
  ModalDialog,
  StatusDropdown,
  UploadButton,
} from '..';
import { IProps } from './type';
import styles from '../../assets/styles/index.module.scss';
import { CheckSibaOrganization } from '../../helpers/Organization';
import { useAppSelector } from '../../store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const GroupHealthInsuranceIndividualsModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  console.log(values);
  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex gap="small" vertical>
          <LabelTitle>Firma Bilgileri</LabelTitle>
          <Flex vertical gap="small" className={styles.widthOneThird}>
            <Select
              id="organization"
              value={
                props.organizations.find(
                  (item) => item.id === (values.organization || organization),
                )?.name || undefined
              }
              placeholder="Firma Seçiniz"
              style={{ width: 200 }}
              disabled={!CheckSibaOrganization()}
              options={props.organizations.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => setFieldValue('organization', value)}
            />
            {touched.organization && errors.organization && (
              <ErrorMessage>{errors.organization}</ErrorMessage>
            )}
          </Flex>
        </Flex>
        <Flex gap="small" vertical>
          <LabelTitle>Poliçe Bilgileri</LabelTitle>
          <Flex vertical gap="small" className={styles.widthOneThird}>
            <Select
              id="policy_number"
              value={
                props.policyTypes.find(
                  (item) => item.key === values.policy_number,
                )?.value || undefined
              }
              placeholder="Poliçe No"
              style={{ width: 200 }}
              options={props.policyTypes.map((item) => ({
                value: item.key,
                label: item.value,
              }))}
              onChange={(value) => setFieldValue('policy_number', value)}
            />
            {touched.policy_number && errors.policy_number && (
              <ErrorMessage>{errors.policy_number}</ErrorMessage>
            )}
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <UploadButton
            fileName={[...values.upload_files]?.map((item) => item.name)}
            uploadRef={uploadInput}
            onChange={props.handleUploadFile}
          />
        )}
        <Flex gap="small" vertical>
          <LabelTitle>Kişi Bilgileri</LabelTitle>
          <Flex justify="space-between">
            <Flex vertical gap="small" className={styles.widthOneThird}>
              <Input placeholder="İsim" {...getFieldProps('first_name')} />
              {touched.first_name && errors.first_name && (
                <ErrorMessage>{errors.first_name}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={styles.widthOneThird}>
              <Input placeholder="Soyisim" {...getFieldProps('last_name')} />
              {touched.last_name && errors.last_name && (
                <ErrorMessage>{errors.last_name}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={styles.widthOneThird}>
              <Input placeholder="TC No" {...getFieldProps('turkish_id')} />
              {touched.turkish_id && errors.turkish_id && (
                <ErrorMessage>{errors.turkish_id}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <Flex justify="space-between">
            <LabelTitle>Durum Bilgisi</LabelTitle>
            <StatusDropdown
              status={values.recent_status}
              statusChoices={props.statusChoices}
              onChange={(value) => setFieldValue('recent_status', value)}
            />
          </Flex>
        )}
        {props.type === 'EDIT' && (
          <MailCheckbox
            checked={values.email_permission}
            onChange={(e: CheckboxChangeEvent) =>
              setFieldValue('email_permission', e.target.checked)
            }
          />
        )}
      </Flex>
    </ModalDialog>
  );
};

export default GroupHealthInsuranceIndividualsModal;
