import type { DataNode } from 'antd/es/tree';
import { IInitialValues } from './type';

export const leftTreeData: DataNode[] = [
  {
    title: 'Nakliyat Sigortaları',
    key: 'T-0',
    children: [
      {
        title: 'Poliçe Görüntüleme',
        key: 'T-0-0',
        children: [
          {
            title: 'Listeleme',
            key: 64,
          },
          {
            title: 'Ekleme',
            key: 61,
          },
          {
            title: 'Güncelleme',
            key: 62,
          },
          {
            title: 'Silme',
            key: 63,
          },
        ],
      },
      {
        title: 'Sefer Sertifikası Görüntüleme',
        key: 'T-0-1',
        children: [
          {
            title: 'Listeleme',
            key: 52,
          },
          {
            title: 'Ekleme',
            key: 49,
          },
          {
            title: 'Güncelleme',
            key: 50,
          },
          {
            title: 'Silme',
            key: 51,
          },
        ],
      },
    ],
  },
  {
    title: 'Hasar Yönetimi',
    key: 'T-1',
    children: [
      {
        title: 'Listeleme',
        key: 80,
      },
      {
        title: 'Ekleme',
        key: 77,
      },
      {
        title: 'Güncelleme',
        key: 78,
      },
      {
        title: 'Silme',
        key: 79,
      },
    ],
  },
  {
    title: 'Oto Sigortalar',
    key: 'T-2',
    children: [
      {
        title: 'Listeleme',
        key: 68,
      },
      {
        title: 'Ekleme',
        key: 65,
      },
      {
        title: 'Güncelleme',
        key: 66,
      },
      {
        title: 'Silme',
        key: 67,
      },
    ],
  },
  {
    title: 'Grup Sağlık',
    key: 'T-4',
    children: [
      {
        title: 'Poliçeler',
        key: 'T-4-0',
        children: [
          {
            title: 'Listeleme',
            key: 104,
          },
          {
            title: 'Ekleme',
            key: 101,
          },
          {
            title: 'Güncelleme',
            key: 102,
          },
          {
            title: 'Silme',
            key: 103,
          },
        ],
      },
      {
        title: 'Sigortalılar',
        key: 'T-4-1',
        children: [
          {
            title: 'Listeleme',
            key: 100,
          },
          {
            title: 'Ekleme',
            key: 97,
          },
          {
            title: 'Güncelleme',
            key: 98,
          },
          {
            title: 'Silme',
            key: 99,
          },
        ],
      },
    ],
  },
  {
    title: 'Seyahat Sağlık',
    key: 'T-5',
    children: [
      {
        title: 'Listeleme',
        key: 96,
      },
      {
        title: 'Ekleme',
        key: 93,
      },
      {
        title: 'Güncelleme',
        key: 94,
      },
      {
        title: 'Silme',
        key: 95,
      },
    ],
  },
];

export const rightTreeData: DataNode[] = [
  {
    title: 'Ana Sigorta Poliçeleri',
    key: 'T-2',
    children: [
      {
        title: 'Yurtiçi Sigortalar',
        key: 'T-2-0',
        children: [
          {
            title: 'Listeleme',
            key: 88,
          },
          {
            title: 'Ekleme',
            key: 85,
          },
          {
            title: 'Güncelleme',
            key: 86,
          },
          {
            title: 'Silme',
            key: 87,
          },
        ],
      },
      {
        title: 'Yurtdışı Sigortalar',
        key: 'T-2-1',
        children: [
          {
            title: 'Listeleme',
            key: 84,
          },
          {
            title: 'Ekleme',
            key: 81,
          },
          {
            title: 'Güncelleme',
            key: 82,
          },
          {
            title: 'Silme',
            key: 83,
          },
        ],
      },
      {
        title: 'Diğer Sigortalar',
        key: 'T-2-2',
        children: [
          {
            title: 'Listeleme',
            key: 92,
          },
          {
            title: 'Ekleme',
            key: 89,
          },
          {
            title: 'Güncelleme',
            key: 90,
          },
          {
            title: 'Silme',
            key: 91,
          },
        ],
      },
    ],
  },
  {
    title: 'Ayarlar',
    key: 'T-3',
    children: [
      {
        title: 'Kullanıcılar',
        key: 'T-3-0',
        children: [
          {
            title: 'Listeleme',
            key: 40,
          },
          {
            title: 'Ekleme',
            key: 37,
          },
          {
            title: 'Güncelleme',
            key: 38,
          },
          {
            title: 'Silme',
            key: 39,
          },
        ],
      },
      {
        title: 'Profiller',
        key: 'T-3-1',
        children: [
          {
            title: 'Listeleme',
            key: 44,
          },
          {
            title: 'Ekleme',
            key: 41,
          },
          {
            title: 'Güncelleme',
            key: 42,
          },
          {
            title: 'Silme',
            key: 43,
          },
        ],
      },
      {
        title: 'Firmalar',
        key: 'T-3-2',
        children: [
          {
            title: 'Listeleme',
            key: 48,
          },
          {
            title: 'Ekleme',
            key: 45,
          },
          {
            title: 'Güncelleme',
            key: 46,
          },
          {
            title: 'Silme',
            key: 47,
          },
        ],
      },
    ],
  },
];

export const INITIAL_VALUES: IInitialValues = {
  name: '',
  leftPermissions: [],
  rightPermissions: [],
  organization: 0,
};
