import { IDataSource, IOrganization } from './type';
import styles from './index.module.scss';
import { AuthorizedIcon } from '../../../components';
import { IRole } from '../ProfileSettings/type';
import TrashSolidBlack from '../../../assets/icons/trash-solid-b-black.svg';
import PenToSquareSolidBlack from '../../../assets/icons/pen-to-square-solid-w-black.svg';
import { Flex, Popconfirm, Tag } from 'antd';
import dayjs from 'dayjs';
import { deactivation_period_months } from '../../../assets/conts';
import { ColumnType } from 'antd/es/table';

export const getColumns = (
  handleClickDelete: (id: number) => Promise<void>,
  handleOpenEditModal: (id: number) => Promise<void>,
  organizations: IOrganization[],
  roles: IRole[],
): Array<ColumnType<IDataSource> & { searchable?: boolean }> => [
  {
    title: 'Firma',
    dataIndex: 'organization',
    key: 'organization',
    searchable: true,
    sorter: (a, b) => a.organization - b.organization,
    render: (_, record) => (
      <div>
        {organizations.find((item) => item.id === record.organization)?.name}
      </div>
    ),
  },
  {
    title: 'İsim',
    dataIndex: 'first_name',
    key: 'first_name',
    sorter: (a, b) => a.first_name.localeCompare(b.first_name),
  },
  {
    title: 'Soyisim',
    dataIndex: 'last_name',
    key: 'last_name',
    sorter: (a, b) => a.last_name.localeCompare(b.last_name),
  },
  {
    title: 'Rol',
    dataIndex: 'role',
    key: 'role',
    searchable: true,
    sorter: (a, b) => a.role.id - b.role.id,
    render: (_, record) => (
      <div>
        {roles.find((item) => item.id === record.role.id)?.external_name}
      </div>
    ),
  },
  {
    title: 'Pozisyon',
    dataIndex: 'position',
    key: 'position',
    searchable: true,
    sorter: (a, b) => a.position.localeCompare(b.position),
  },
  {
    title: 'Departman',
    dataIndex: 'department',
    key: 'department',
    sorter: (a, b) => a.department.localeCompare(b.department),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: 'Telefon',
    dataIndex: 'phone_number',
    key: 'phone_number',
    sorter: (a, b) => a.phone_number.localeCompare(b.phone_number),
  },
  {
    title: 'Aktiflik Periyodu',
    dataIndex: 'deactivation_period_months',
    key: 'deactivation_period_months',
    sorter: (a, b) =>
      a.deactivation_period_months - b.deactivation_period_months,
    render: (_, record) => (
      <div>{deactivation_period_months[record.deactivation_period_months]}</div>
    ),
  },
  {
    title: 'Aktiflik Güncelleme Tarihi',
    dataIndex: 'deactivation_period_months_updated_at',
    key: 'deactivation_period_months_updated_at',
    sorter: (a, b) =>
      a.deactivation_period_months_updated_at.localeCompare(
        b.deactivation_period_months_updated_at,
      ),
    render: (_, record) => (
      <div>
        {record.deactivation_period_months_updated_at
          ? dayjs(record.deactivation_period_months_updated_at).format(
              'DD/MM/YYYY',
            )
          : null}
      </div>
    ),
  },
  {
    title: 'Aktiflik Bitiş Tarihi',
    dataIndex: 'deactivation_date',
    key: 'deactivation_date',
    sorter: (a, b) => a.deactivation_date.localeCompare(b.deactivation_date),
    render: (_, record) => (
      <div>
        {record.deactivation_date
          ? dayjs(record.deactivation_date).format('DD/MM/YYYY')
          : null}
      </div>
    ),
  },
  {
    title: 'Kayıt Tarihi',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: (a, b) => a.created_at.localeCompare(b.created_at),
    render: (_, record) => (
      <div>
        {record.created_at
          ? dayjs(record.created_at).format('DD/MM/YYYY')
          : null}
      </div>
    ),
  },
  {
    title: 'Oluşturan Kullanıcı',
    dataIndex: 'created_by',
    key: 'created_by',
    sorter: (a, b) => a.created_by.localeCompare(b.created_by),
  },
  {
    title: 'Durum',
    dataIndex: 'is_active',
    key: 'is_active',
    sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
    render: (_: unknown, record: IDataSource) => (
      <Tag color={record.is_active ? '#87A330' : '#D6222A'}>
        {record.is_active ? 'Aktif' : 'Silindi'}
      </Tag>
    ),
  },
  {
    title: 'İşlem',
    dataIndex: 'action',
    key: 'action',
    render: (_: unknown, record: IDataSource) => (
      <Flex justify="flex-end" gap={14}>
        <Popconfirm
          title={
            <div className={styles.popconfirmTitle}>
              Silme işlemini onaylıyor musunuz?
            </div>
          }
          overlayInnerStyle={{
            width: 150,
            borderColor: '#D6222A',
            borderWidth: 1,
            borderStyle: 'solid',
          }}
          okText="Onayla"
          cancelText="İptal"
          icon={null}
          color="#FAFAFA"
          okButtonProps={{ danger: true, style: { width: '45%' } }}
          cancelButtonProps={{ danger: true, style: { width: '45%' } }}
          onConfirm={() => record.id && handleClickDelete(record.id)}
        >
          <AuthorizedIcon
            action="delete"
            model="user"
            source={TrashSolidBlack}
          />
        </Popconfirm>
        <AuthorizedIcon
          action="change"
          model="user"
          source={PenToSquareSolidBlack}
          onClick={() => record?.id && handleOpenEditModal(record.id)}
        />
      </Flex>
    ),
  },
];
