import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  /* organization: Yup.number().notOneOf([0], 'Firma zorunludur.'),
  branch: Yup.string().required('Branş zorunludur.'),
  country: Yup.string().required('Ülke zorunludur.'),
  start_date: Yup.string().required('Başlangıç tarihi zorunludur.'),
  end_date: Yup.string().required('Bitiş tarihi zorunludur.'), */
});

export default validationSchema;
