import { FC } from 'react';
import { Button, Flex, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { IProps } from './type';
import styles from './index.module.scss';

const UploadButton: FC<IProps> = ({ fileName, uploadRef, onChange }) => {
  return (
    <Flex justify="space-between">
      <Typography className={styles.uploadName}>
        {fileName?.join(', ') ?? 'Ek Dosya'}
      </Typography>
      <Flex vertical align="flex-end">
        <input
          hidden
          ref={uploadRef}
          type="file"
          multiple
          onChange={onChange}
        />
        <Button
          className={styles.container}
          onClick={() => uploadRef.current?.click()}
        >
          <PlusOutlined className={styles.icon} />
          <div className={styles.text}>Dosya Ekle</div>
        </Button>
      </Flex>
    </Flex>
  );
};

export default UploadButton;
