import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  AuthorizedButton,
  Container,
  DataTable,
  PageTitle,
  UserModal,
} from '../../../components';
import {
  IDataSource,
  IInitialValues,
  IOrganization,
  IRoleResponse,
} from './type';
import { INITIAL_VALUES } from './const';
import styles from './index.module.scss';
import { RequestMethods } from '../../../services/enum';
import UseHttpService from '../../../services/hook';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IPage,
  ISearch,
  ISort,
} from '../../../store/reducers/tableReducer/type';
import validationSchema from './validations';
import {
  cleanupAfterSubmit,
  prepareFetchParameters,
  setFormForSelectedUser,
} from './helpers';
import { getColumns } from './columns';
import { tableActions } from '../../../store/reducers/tableReducer';
import { IRole } from '../ProfileSettings/type';
import { IResponse } from '../../../types';

const UserSettings: FC = () => {
  const search = useAppSelector((state) => state.tableReducer.search);
  const sort = useAppSelector((state) => state.tableReducer.sort);
  const page = useAppSelector((state) => state.tableReducer.page);
  const dispatch = useAppDispatch();
  const executeService = UseHttpService();

  // State
  const [selectedUser, setSelectedUser] = useState<IDataSource | undefined>(
    undefined,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IDataSource[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [organizationRoles, setOrganizationRoles] = useState<IRole[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<
    number | undefined
  >(undefined);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);

  // Handlers
  const handleFetchOrganizations = async () => {
    const response = await executeService<IResponse<IOrganization>>({
      method: RequestMethods.GET,
      url: `auth/organization/`,
    });
    if (response && 'results' in response) {
      setOrganizations(response.results);
    }
  };

  const handleFetchOrganizationRoles = async (id: number | undefined) => {
    if (!id) return;
    try {
      const response = await executeService<IRole[]>({
        method: RequestMethods.GET,
        url: `auth/organization/${id}/roles/`,
      });

      if (Array.isArray(response)) {
        setOrganizationRoles(response);
      }
    } catch (error) {
      console.error('Error fetching organization roles:', error);
      setOrganizationRoles([]);
    }
  };

  const handleFetchData = async (
    search?: ISearch[],
    sort?: ISort,
    page?: IPage,
  ) => {
    const parameters = prepareFetchParameters(search, sort, page);
    const response = await executeService<IResponse<IDataSource>>({
      method: RequestMethods.GET,
      url: `auth/user/${parameters}`,
    });
    if (response && 'results' in response) {
      dispatch(tableActions.changeTableTotal(response.count));
      setDataSource(response.results);
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleOpenEditModal = async (id: number) => {
    const selectedUser = await executeService<IDataSource>({
      method: RequestMethods.GET,
      url: `auth/user/${id}/`,
    });
    selectedUser && 'id' in selectedUser && setSelectedUser(selectedUser);
  };

  const handleCloseEditModal = () => {
    setSelectedUser(undefined);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleFetchRoles = async () => {
    try {
      const response = await executeService<IRoleResponse>({
        method: RequestMethods.GET,
        url: 'auth/role/',
      });
      if (response && Array.isArray(response)) {
        setRoles(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickDelete = async (id: number) => {
    try {
      await executeService<unknown>({
        method: RequestMethods.DELETE,
        url: `auth/user/${id}/`,
      });
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedUser,
        formik.resetForm,
        handleFetchData,
      );
    }
  };

  const handleSubmit = async (values: IInitialValues) => {
    try {
      if (selectedUser) {
        await executeService<IDataSource>({
          method: RequestMethods.PUT,
          url: `auth/user/${selectedUser.id}/`,
          data: values,
        });
      } else {
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: 'auth/user/',
          data: values,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedUser,
        formik.resetForm,
        handleFetchData,
      );
      dispatch(tableActions.changeTablePage({ number: 0, size: 20 }));
    }
  };

  // Columns
  const columns = getColumns(
    handleClickDelete,
    handleOpenEditModal,
    organizations,
    roles,
  );

  // Form Management
  const formik = useFormik<IInitialValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: handleSubmit,
  });

  // Effects
  useEffect(() => {
    if (selectedUser) {
      const values = setFormForSelectedUser(selectedUser);
      formik.setValues(values);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [selectedUser]);

  useEffect(() => {
    handleFetchData(search, sort, page);
  }, [search, sort.key, sort.order, page.number, page.size]);

  useEffect(() => {
    handleFetchRoles();
    handleFetchOrganizations();
  }, []);

  useEffect(() => {
    handleFetchOrganizationRoles(selectedOrganization || undefined);
  }, [selectedOrganization]);

  // JSX Return
  return (
    <Container>
      <PageTitle title="Kullanıcı Ayarları" />
      <Flex vertical gap="middle">
        <Flex justify="flex-end">
          <AuthorizedButton
            model="user"
            action="add"
            className={styles.button}
            onClick={handleOpenModal}
          >
            <PlusOutlined className={styles.buttonIcon} />
            <div className={styles.buttonText}>Yeni Kullanıcı Ekle</div>
          </AuthorizedButton>
        </Flex>
        <DataTable<IDataSource>
          scrollable
          columns={columns}
          dataSource={dataSource}
        />
      </Flex>
      <UserModal
        type="CREATE"
        open={isModalOpen}
        title="Yeni Kullanıcı Oluşturma"
        subTitle="Yeni Kullanıcı Oluşturma"
        formik={formik}
        roles={organizationRoles}
        organizations={organizations}
        setSelectedOrganization={setSelectedOrganization}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseModal}
      />
      <UserModal
        type="EDIT"
        open={!!selectedUser}
        title="Kullanıcı Güncelleme"
        subTitle="Kullanıcı Güncelleme"
        formik={formik}
        roles={organizationRoles}
        organizations={organizations}
        setSelectedOrganization={setSelectedOrganization}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseEditModal}
      />
    </Container>
  );
};

export default UserSettings;
