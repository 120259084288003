import { FC, useEffect } from 'react';
import { Button, Flex, Input, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { ErrorMessage, LabelTitle, ModalDialog } from '../';
import { IProps } from './type';
import styles from './index.module.scss';
import globalStyles from '../../assets/styles/index.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { deactivation_period_months_options } from '../../assets/conts';

const UserModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;

  const navigate = useNavigate();

  const handleClickCreateProfile = () =>
    navigate('/user-profiles-and-authorisation/profile-settings');

  useEffect(() => {
    if (values.organization) {
      props.setSelectedOrganization(values.organization);
    }
  }, [values.organization, props.setSelectedOrganization]);

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex vertical gap="small">
          <LabelTitle>Firma Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Select
                id="organization"
                value={
                  props.organizations.find(
                    (item) => item.id === values.organization,
                  )?.name || undefined
                }
                placeholder="Firma Seçiniz"
                options={props.organizations.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={(value) => setFieldValue('organization', value)}
              />
              {touched.organization && errors.organization && (
                <ErrorMessage>{errors.organization}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Input
                placeholder="Departman Adı"
                {...getFieldProps('department')}
              />
              {touched.department && errors.department && (
                <ErrorMessage>{errors.department}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Input
                placeholder="Pozisyon Adı"
                {...getFieldProps('position')}
              />
              {touched.position && errors.position && (
                <ErrorMessage>{errors.position}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex vertical gap="small">
          <LabelTitle>Kullanıcı Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Input placeholder="İsim" {...getFieldProps('first_name')} />
              {touched.first_name && errors.first_name && (
                <ErrorMessage>{errors.first_name}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Input placeholder="Soyisim" {...getFieldProps('last_name')} />
              {touched.last_name && errors.last_name && (
                <ErrorMessage>{errors.last_name}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Select
                id="deactivation_period_months"
                value={values.deactivation_period_months}
                placeholder="Aktiflik Süresi"
                options={deactivation_period_months_options}
                onChange={(value) =>
                  setFieldValue('deactivation_period_months', value)
                }
              />
              {touched.deactivation_period_months &&
                errors.deactivation_period_months && (
                  <ErrorMessage>
                    {errors.deactivation_period_months}
                  </ErrorMessage>
                )}
            </Flex>
          </Flex>
        </Flex>
        <Flex vertical gap="small">
          <LabelTitle>İletişim Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthHalf}>
              <Input placeholder="Email" {...getFieldProps('email')} />
              {touched.email && errors.email && (
                <ErrorMessage>{errors.email}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthHalf}>
              <Input placeholder="Telefon" {...getFieldProps('phone_number')} />
              {touched.phone_number && errors.phone_number && (
                <ErrorMessage>{errors.phone_number}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex vertical gap="small">
          <LabelTitle>Profil Bilgileri</LabelTitle>
          <Flex justify="space-between">
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Select
                id="role"
                value={
                  props.roles.find((item) => item.id === values.role)
                    ?.external_name || undefined
                }
                placeholder="Profil"
                options={props.roles.map((role) => ({
                  label: role.external_name,
                  value: role.id,
                }))}
                onChange={(value) => setFieldValue('role', value)}
              />
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Button
                className={styles.addButton}
                onClick={handleClickCreateProfile}
              >
                <PlusOutlined className={styles.buttonIcon} />
                <div className={styles.buttonText}>Profil Oluştur</div>
              </Button>
            </Flex>
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <>
            <Flex justify="space-between">
              <LabelTitle>Oluşturan Kullanıcı</LabelTitle>
              <Typography className={styles.labelValue}>
                {values.created_by}
              </Typography>
            </Flex>
            <Flex justify="space-between">
              <LabelTitle>Kayıt Tarihi</LabelTitle>
              <Typography className={styles.labelValue}>
                {values.created_at
                  ? dayjs(values.created_at).format('DD/MM/YYYY')
                  : null}
              </Typography>
            </Flex>
          </>
        )}
      </Flex>
    </ModalDialog>
  );
};

export default UserModal;
