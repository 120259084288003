import * as Yup from 'yup';
import dayjs from 'dayjs';

const validationSchemaForCreate = Yup.object().shape({
  organization: Yup.number().notOneOf([0], 'Firma zorunludur'),
  branch: Yup.array().min(1, 'Branş zorunludur'),
  upload_files: Yup.array()
    .min(1, 'Ek dosya zorunludur')
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Ek dosya zorunludur')
          .min(1, 'Ek dosya zorunludur'),
      }),
    ),
  start_date: Yup.string()
    .required('Başlangıç tarihi zorunludur.')
    .test(
      'start_date-before-end_date',
      'Başlangıç tarihi bitiş tarihinden sonra olamaz.',
      function (value) {
        const { end_date } = this.parent;
        if (!value || !end_date) return true;

        const startDate = dayjs(value, 'YYYY-MM-DD', true);
        const endDate = dayjs(end_date, 'YYYY-MM-DD', true);

        if (!startDate.isValid() || !endDate.isValid()) {
          console.error('Geçersiz tarih formatı');
          return false;
        }

        return startDate.isBefore(endDate) || startDate.isSame(endDate);
      },
    ),
  end_date: Yup.string()
    .required('Bitiş tarihi zorunludur.')
    .test(
      'end_date-after-start_date',
      'Bitiş tarihi başlangıç tarihinden önce olamaz.',
      function (value) {
        const { start_date } = this.parent;
        if (!value || !start_date) return true;

        const endDate = dayjs(value, 'YYYY-MM-DD', true);
        const startDate = dayjs(start_date, 'YYYY-MM-DD', true);

        if (!endDate.isValid() || !startDate.isValid()) {
          console.error('Geçersiz tarih formatı');
          return false;
        }

        return endDate.isAfter(startDate) || endDate.isSame(startDate);
      },
    ),
});

const validationSchemaForEdit = Yup.object().shape({
  organization: Yup.number().notOneOf([0], 'Firma zorunludur'),
  upload_files: Yup.array()
    .min(1, 'Ek dosya zorunludur')
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Ek dosya zorunludur')
          .min(1, 'Ek dosya zorunludur'),
      }),
    ),
  start_date: Yup.string()
    .required('Başlangıç tarihi zorunludur.')
    .test(
      'start_date-before-end_date',
      'Başlangıç tarihi bitiş tarihinden sonra olamaz.',
      function (value) {
        const { end_date } = this.parent;
        if (!value || !end_date) return true;

        const startDate = dayjs(value, 'YYYY-MM-DD', true);
        const endDate = dayjs(end_date, 'YYYY-MM-DD', true);

        if (!startDate.isValid() || !endDate.isValid()) {
          console.error('Geçersiz tarih formatı');
          return false;
        }

        return startDate.isBefore(endDate) || startDate.isSame(endDate);
      },
    ),
  end_date: Yup.string()
    .required('Bitiş tarihi zorunludur.')
    .test(
      'end_date-after-start_date',
      'Bitiş tarihi başlangıç tarihinden önce olamaz.',
      function (value) {
        const { start_date } = this.parent;
        if (!value || !start_date) return true;

        const endDate = dayjs(value, 'YYYY-MM-DD', true);
        const startDate = dayjs(start_date, 'YYYY-MM-DD', true);

        if (!endDate.isValid() || !startDate.isValid()) {
          console.error('Geçersiz tarih formatı');
          return false;
        }

        return endDate.isAfter(startDate) || endDate.isSame(startDate);
      },
    ),
});

export { validationSchemaForCreate, validationSchemaForEdit };
