import {
  IPage,
  ISearch,
  ISort,
} from '../../../store/reducers/tableReducer/type';
import { IDataSource, IInitialValues } from './type';

export const prepareFetchParameters = (
  search?: ISearch[],
  sort?: ISort,
  page?: IPage,
): string => {
  const params = new URLSearchParams();

  page?.size && params.append('limit', page?.size.toString());
  typeof page?.number === 'number' &&
    params.append('offset', (page.size * page.number).toString());
  search?.length &&
    search.forEach((item) => params.append(item.key, item.value));
  sort?.key &&
    sort.order &&
    params.append(
      'ordering',
      sort.order === 'ascend' ? sort.key : `-${sort.key}`,
    );

  return params.size ? `?${params.toString()}` : '';
};

export const cleanupAfterSubmit = (
  setIsModalOpen: (open: boolean) => void,
  setselectedItem: (policy?: IDataSource) => void,
  formikReset: () => void,
  handleFetchData: () => void,
) => {
  setIsModalOpen(false);
  setselectedItem(undefined);
  formikReset();
  handleFetchData();
};

export const setFormForSelectedItem = (
  selectedItem: IDataSource,
): IInitialValues => {
  return {
    id: selectedItem.id,
    name: selectedItem.name,
    is_active: selectedItem.is_active,
    tax_number: selectedItem.tax_number,
    tax_office: selectedItem.tax_office,
    address: selectedItem.address,
  };
};
