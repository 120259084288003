import { FC, useRef } from 'react';
import { DatePicker, Flex, Input, Select } from 'antd';
import dayjs from 'dayjs';
import {
  ErrorMessage,
  LabelTitle,
  MailCheckbox,
  ModalDialog,
  StatusDropdown,
  UploadButton,
} from '../';
import { IProps } from './type';
import styles from './index.module.scss';
import globalStyles from '../../assets/styles/index.module.scss';
import { CheckSibaOrganization } from '../../helpers/Organization';
import { useAppSelector } from '../../store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const ExpeditionCertificateModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex align="flex-start" justify="space-between">
          <LabelTitle>Firma Bilgileri</LabelTitle>
          <Flex vertical gap="small">
            <Select
              id="organization"
              value={
                props.organizations.find(
                  (item) => item.id === (values.organization || organization),
                )?.name || undefined
              }
              placeholder="Firma Seçiniz"
              style={{ width: 200 }}
              className={styles.input}
              disabled={!CheckSibaOrganization()}
              options={props.organizations.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => setFieldValue('organization', value)}
            />
            {touched.organization && errors.organization && (
              <ErrorMessage>{errors.organization}</ErrorMessage>
            )}
          </Flex>
        </Flex>
        <Flex gap="small" vertical>
          <LabelTitle>Emtia Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Input
                placeholder="Emtia Cinsi"
                {...getFieldProps('goods_type')}
              />
              {touched.goods_type && errors.goods_type && (
                <ErrorMessage>{errors.goods_type}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Input
                type="number"
                placeholder="Emtia Miktarı"
                {...getFieldProps('goods_quantity')}
              />
              {touched.goods_quantity && errors.goods_quantity && (
                <ErrorMessage>{errors.goods_quantity}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Select
                id="goods_unit"
                value={values.goods_unit || undefined}
                placeholder="Emtia Birimi"
                options={props.unitOfGoods.map((uog) => ({
                  value: uog.key,
                  label: uog.value,
                }))}
                onChange={(value) => setFieldValue('goods_unit', value)}
              />
              {touched.goods_unit && errors.goods_unit && (
                <ErrorMessage>{errors.goods_unit}</ErrorMessage>
              )}
            </Flex>
          </Flex>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Input
                type="number"
                placeholder="Emtia Bedeli"
                {...getFieldProps('insurance_value')}
              />
              {touched.insurance_value && errors.insurance_value && (
                <ErrorMessage>{errors.insurance_value}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Select
                id="insurance_value_unit"
                value={values.insurance_value_unit || undefined}
                placeholder="Para Birimi"
                options={props.currencies.map((c) => ({
                  value: c.key,
                  label: c.value,
                }))}
                onChange={(value) =>
                  setFieldValue('insurance_value_unit', value)
                }
              />
              {touched.insurance_value_unit && errors.insurance_value_unit && (
                <ErrorMessage>{errors.insurance_value_unit}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <DatePicker
                id="invoice_date"
                name="invoice_date"
                format="DD/MM/YYYY"
                value={
                  dayjs(values.invoice_date).isValid()
                    ? dayjs(values.invoice_date)
                    : null
                }
                placeholder="Fatura Tarihi"
                onChange={(date) =>
                  setFieldValue(
                    'invoice_date',
                    dayjs(date).format('DD/MM/YYYY'),
                  )
                }
              />
              {touched.invoice_date && errors.invoice_date && (
                <ErrorMessage>{errors.invoice_date}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Input
                placeholder="Fatura No"
                {...getFieldProps('invoice_number')}
              />
              {touched.invoice_number && errors.invoice_number && (
                <ErrorMessage>{errors.invoice_number}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <Flex gap="small" vertical>
            <LabelTitle>Poliçe Bilgileri</LabelTitle>
            <Flex justify="space-between" gap="small">
              <Flex vertical gap="small" className={globalStyles.widthOneThird}>
                <Input
                  type="number"
                  placeholder="Poliçe Değeri"
                  {...getFieldProps('policy_value')}
                />
                {touched.policy_value && errors.policy_value && (
                  <ErrorMessage>{errors.policy_value}</ErrorMessage>
                )}
              </Flex>
              <Flex vertical gap="small" className={globalStyles.widthOneThird}>
                <Select
                  id="policy_value_unit"
                  value={values.policy_value_unit || undefined}
                  placeholder="Değer Birimi"
                  options={props.currencies.map((c) => ({
                    value: c.key,
                    label: c.value,
                  }))}
                  onChange={(value) =>
                    setFieldValue('policy_value_unit', value)
                  }
                />
                {touched.policy_value_unit && errors.policy_value_unit && (
                  <ErrorMessage>{errors.policy_value_unit}</ErrorMessage>
                )}
              </Flex>
              <Flex vertical gap="small" className={globalStyles.widthOneThird}>
                <Input
                  placeholder="Poliçe No"
                  {...getFieldProps('policy_number')}
                />
                {touched.policy_number && errors.policy_number && (
                  <ErrorMessage>{errors.policy_number}</ErrorMessage>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
        <Flex vertical gap="small">
          <LabelTitle>Sevkiyat Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthHalf}>
              <Select
                id="transportation_type"
                value={values.transportation_type || undefined}
                placeholder="Sevkiyat Tipi"
                options={props.transportationTypes.map((tt) => ({
                  value: tt.key,
                  label: tt.value,
                }))}
                onChange={(value) =>
                  setFieldValue('transportation_type', value)
                }
              />
              {touched.transportation_type && errors.transportation_type && (
                <ErrorMessage>{errors.transportation_type}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthHalf}>
              <DatePicker
                id="expedition_date"
                name="expedition_date"
                format="DD/MM/YYYY"
                value={
                  dayjs(values.expedition_date).isValid()
                    ? dayjs(values.expedition_date)
                    : null
                }
                placeholder="Sevk Tarihi"
                onChange={(date) =>
                  setFieldValue(
                    'expedition_date',
                    dayjs(date).format('DD/MM/YYYY'),
                  )
                }
              />
              {touched.expedition_date && errors.expedition_date && (
                <ErrorMessage>{errors.expedition_date}</ErrorMessage>
              )}
            </Flex>
          </Flex>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthHalf}>
              <Input
                placeholder="Çıkış Ülkesi"
                {...getFieldProps('origin_country')}
              />
              {touched.origin_country && errors.origin_country && (
                <ErrorMessage>{errors.origin_country}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthHalf}>
              <Input
                placeholder="Varış Ülkesi"
                {...getFieldProps('destination_country')}
              />
              {touched.destination_country && errors.destination_country && (
                <ErrorMessage>{errors.destination_country}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <UploadButton
            fileName={[...values.upload_files]?.map((item) => item.name)}
            uploadRef={uploadInput}
            onChange={props.handleUploadFile}
          />
        )}
        <Flex vertical gap="small">
          <LabelTitle>Taşıt Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthHalf}>
              <Select
                id="vehicle_type"
                value={values.vehicle_type || undefined}
                placeholder="Taşıt Cinsi"
                options={props.vehicleTypes.map((vt) => ({
                  value: vt.key,
                  label: vt.value,
                }))}
                onChange={(value) => setFieldValue('vehicle_type', value)}
              />
              {touched.vehicle_type && errors.vehicle_type && (
                <ErrorMessage>{errors.vehicle_type}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthHalf}>
              <Input
                placeholder="Taşıt Bilgisi"
                {...getFieldProps('vehicle_info')}
              />
              {touched.vehicle_info && errors.vehicle_info && (
                <ErrorMessage>{errors.vehicle_info}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <Flex justify="space-between">
            <LabelTitle>Durum Bilgisi</LabelTitle>
            <StatusDropdown
              status={values.recent_status}
              statusChoices={props.statusChoices}
              onChange={(value) => setFieldValue('recent_status', value)}
            />
          </Flex>
        )}
        <Flex vertical gap="small">
          <LabelTitle>Açıklama</LabelTitle>
          <Flex vertical gap="small">
            <Input.TextArea
              placeholder="Açıklama"
              {...getFieldProps('description')}
            />
            {touched.description && errors.description && (
              <ErrorMessage>{errors.description}</ErrorMessage>
            )}
          </Flex>
        </Flex>
        {props.type === 'EDIT' && (
          <MailCheckbox
            checked={values.email_permission}
            onChange={(e: CheckboxChangeEvent) =>
              setFieldValue('email_permission', e.target.checked)
            }
          />
        )}
      </Flex>
    </ModalDialog>
  );
};

export default ExpeditionCertificateModal;
