import { FC, useRef } from 'react';
import { DatePicker, Flex, Input, Select } from 'antd';
import {
  ErrorMessage,
  LabelTitle,
  MailCheckbox,
  ModalDialog,
  UploadButton,
} from '../';
import { IProps } from './type';
import styles from '../../assets/styles/index.module.scss';
import dayjs from 'dayjs';
import { CheckSibaOrganization } from '../../helpers/Organization';
import { useAppSelector } from '../../store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const PolicyModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex align="flex-start" justify="space-between">
          <LabelTitle>Firma Bilgileri</LabelTitle>
          <Flex vertical gap="small">
            <Select
              id="organization"
              value={
                props.organizations.find(
                  (item) => item.id === (values.organization || organization),
                )?.name || undefined
              }
              placeholder="Firma Seçiniz"
              style={{ width: 200 }}
              disabled={!CheckSibaOrganization()}
              options={props.organizations.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => setFieldValue('organization', value)}
            />
            {touched.organization && errors.organization && (
              <ErrorMessage>{errors.organization}</ErrorMessage>
            )}
          </Flex>
        </Flex>
        <Flex gap="small" vertical>
          <LabelTitle>Poliçe Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={styles.widthHalf}>
              <Select
                id="policy_type"
                value={values.policy_type || undefined}
                placeholder="Poliçe Tipi"
                options={props.policyTypes.map((pt) => ({
                  value: pt.key,
                  label: pt.value,
                }))}
                onChange={(value) => setFieldValue('policy_type', value)}
              />
              {touched.policy_type && errors.policy_type && (
                <ErrorMessage>{errors.policy_type}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={styles.widthHalf}>
              <Input
                placeholder="Poliçe No"
                {...getFieldProps('policy_number')}
              />
              {touched.policy_number && errors.policy_number && (
                <ErrorMessage>{errors.policy_number}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        <UploadButton
          fileName={[values.upload_files.name]}
          uploadRef={uploadInput}
          onChange={props.handleUploadFile}
        />
        <></>
        {values.policy_type !== 'nakliyat_sefer_poliçesi' && (
          <Flex gap="small" vertical>
            <LabelTitle>Tarih Aralıgı</LabelTitle>
            <Flex justify="space-between" gap="small">
              <Flex vertical gap="small" className={styles.widthHalf}>
                <DatePicker
                  id="start_date"
                  name="start_date"
                  value={
                    values.start_date
                      ? dayjs(values.start_date, 'YYYY-MM-DD')
                      : null
                  }
                  className={styles.input}
                  placeholder="Başlangıç Tarihi"
                  onChange={(date) => {
                    if (date) {
                      setFieldValue('start_date', date.format('YYYY-MM-DD'));
                    }
                  }}
                />
                {touched.start_date && errors.start_date && (
                  <ErrorMessage>{errors.start_date}</ErrorMessage>
                )}
              </Flex>
              <Flex vertical gap="small" className={styles.widthHalf}>
                <DatePicker
                  id="end_date"
                  name="end_date"
                  value={
                    values.end_date
                      ? dayjs(values.end_date, 'YYYY-MM-DD')
                      : null
                  }
                  className={styles.input}
                  placeholder="Bitiş Tarihi"
                  onChange={(date) => {
                    if (date) {
                      setFieldValue('end_date', date.format('DD/MM/YYYY'));
                    }
                  }}
                />
                {touched.end_date && errors.end_date && (
                  <ErrorMessage>{errors.end_date}</ErrorMessage>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
        {props.type === 'CREATE' && (
          <MailCheckbox
            checked={values.email_permission}
            onChange={(e: CheckboxChangeEvent) =>
              setFieldValue('email_permission', e.target.checked)
            }
          />
        )}
      </Flex>
    </ModalDialog>
  );
};

export default PolicyModal;
